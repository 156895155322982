* {
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--textPrimary);
  width: 100%;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-cell-wrapper {
  justify-content: center;
  overflow: hidden;
}

.ag-custom-cell-aligment-center {
  text-align: center;
}

.inactive > span.recharts-legend-item-text {
  text-decoration: line-through;
  opacity: 0.45;
  color: var(--textPrimary) !important;
  font-style: italic;
}

.recharts-legend-wrapper {
  padding-bottom: 15px !important;
  padding-top: 10px !important;
}

ul.recharts-default-legend > li {
  margin-right: 0 !important;
  padding-right: 10px;
  cursor: pointer;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
  background: gray;
  /* border-radius: 100px; */
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
}

/* @media screen and (max-width: 425px) {
  div.ag-theme-alpine div.ag-row,
  div.ag-theme-alpine-dark div.ag-row,
  div.ag-theme-alpine div.ag-header,
  div.ag-theme-alpine-dark div.ag-header {
    font-size: 8px !important;
  }
} */

div.MuiAlert-icon > svg.MuiSvgIcon-root {
  color: white;
}

.button-link {
  text-decoration: none;
  color: inherit;
}

.stavkats-hidden {
  display: none;
  visibility: hidden;
}

.stock-table-custom-tooltip-container {
  background-color: #2c2c2c;
  border: 1px solid gray;
}

.stock-table-custom-tooltip-header {
  background-color: rgb(70, 70, 70);
  font-size: large;
  padding: 5px 7px;
}

.stock-table-custom-tooltip-body {
  padding: 5px 10px;
  font-size: medium;
  overflow-y: scroll;
  max-height: 200px;
  .sell {
    color: #f3622d;
  }
  .buy {
    color: #57b757;
  }
}

.stock-table-custom-tooltip-body > * + * {
  margin: 3px 0px;
}
