:root {
  --sidebarWidth: 150px;
  --itemMarginLeft: 6px;
  --openSpeed: 0.4s;
}

.full_width {
  width: var(--sidebarWidth);
}

.container {
  background-color: var(--lighterBack);
  height: 100vh;
  min-width: 52px;
  width: 52px;
  transition: width var(--openSpeed) ease;
  /* overflow: hidden; */
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
  z-index: 100;
}

.chevron_box {
  width: 100%;
  display: flex;
  margin-top: 5px;
  justify-content: flex-end;
}

.divider {
  border-bottom: 1px solid rgb(105, 105, 105);
  width: 100%;
  margin: 5px 0px;
}

.chevron {
  margin-right: 7px;
  padding: 2px;
}

@media (hover: hover) {
  .chevron:hover {
    background-color: var(--hover);
    border-radius: 50%;
    cursor: pointer;
  }
}

.open {
  /* min-width: calc(var(--sidebarWidth) + 25px); */
  min-width: unset;
  width: calc(var(--sidebarWidth) + 25px);
}

.section {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.section > * {
  margin-bottom: 4px;
}

.hidden {
  opacity: 0;
}
/******************* SiderbarItem *******************/
.item_container {
  width: var(--sidebarWidth);
  display: flex;
  align-items: center;
}

.item_main {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px 0px;
  width: 97%;
  margin-left: var(--itemMarginLeft);
  transition: background-color 0.2s ease-in-out;
}

.item_main > * + * {
  margin-left: 5px;
}

.item_icon_open,
.item_icon {
  padding: 4px;
}

.item_label {
  margin-left: 4px;
}

.item_icon {
  margin: 2px 0px 2px var(--itemMarginLeft);
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

@media (hover: hover) {
  .item_main:hover,
  .item_icon:hover {
    background-color: var(--hover);
    cursor: pointer;
  }
}

.slide_in {
  animation: slidein var(--openSpeed);
}

@keyframes slidein {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.fade_in {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/******************* SiderbarItem *******************/

/* @media screen and (max-width: 1200px) {
  .container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
} */
