:root {
  --circleRadius: 21px;
  --containerWidth: 52px;
  --containerHeight: 29px;
  --sliderColor: white;
  --sliderColorBefore: rgb(243, 243, 243);
  --sliderBackColor: #ccc;
  --buttonBackColor: var(--primary);
}
.small {
  --circleRadius: 16px;
  --containerWidth: 41px;
  --containerHeight: 23px;
}
.large {
  --circleRadius: 26px;
  --containerWidth: 62px;
  --containerHeight: 34px;
}

.container {
  position: relative;
  display: inline-block;
  width: var(--containerWidth);
  height: var(--containerHeight);
  margin: 5px;
}

.container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sliderBackColor);
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: var(--circleRadius);
  width: var(--circleRadius);
  margin: 4px;
  background-color: var(--sliderColorBefore); /* slider off color */
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.2s ease-in-out;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--buttonBackColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--buttonBackColor);
}

input:checked + .slider:before {
  -webkit-transform: translateX(var(--circleRadius));
  -ms-transform: translateX(var(--circleRadius));
  transform: translateX(var(--circleRadius));
  background-color: var(--sliderColor); /* slider on color */
}

